import React, { Component } from "reactn";
import { getStaticUrl } from "js/config";
import { getQueryVariables } from "js/core/utilities/utilities";
import Spinner from "js/react/components/Spinner";
import Api from "js/core/api";

import "css/slack.scss";

class RedirectHandler extends Component {
    state = {
        fetching: true,
        header: "",
        message: "",
        showUpgradeButton: false
    }

    componentDidMount() {
        this.processRedirect();
    }

    finishRedirect(errorMessage = null) {
        const state = getQueryVariables("state");
        if (state === "isPopup") {
            // Posting a message and closing the window only if it's a popup
            window.opener.postMessage({ errorMessage, isFromRedirectHandler: true }, window.location.origin);
            window.close();
            return;
        }

        if (errorMessage) {
            this.setState({
                fetching: false,
                header: "Error",
                message: errorMessage
            });
            return;
        }

        this.setState({
            fetching: false,
            header: "Slack integration was successfully enabled"
        });
    }

    async processRedirect() {
        // Slack sent us an error message, displaying it and not going further
        const errorMessage = getQueryVariables("error");
        if (errorMessage) {
            return this.finishRedirect(errorMessage);
        }

        const code = getQueryVariables("code");
        try {
            await Api.slackEnable.post({ code });
            this.finishRedirect();
        } catch (err) {
            this.finishRedirect(err.message);
        }
    }

    render() {
        const { fetching, header, message, showUpgradeButton } = this.state;

        return (<div className="slack-redirect-handler">
            <img className="logo" onClick={() => window.open("/?", "_blank")} src={getStaticUrl("/images/beautifulai-logos/beautifulai-logo-reverse.svg")} alt="logo" />
            {fetching && <Spinner />}
            {!fetching &&
                <div className="content-container">
                    {header &&
                        <h1>
                            {header}
                        </h1>
                    }
                    {message &&
                        <h2>
                            {message}
                        </h2>
                    }
                    {showUpgradeButton &&
                        <button className="go-to-bai-button" onClick={() => window.location.href = "/pricing"}>
                            Upgrade to pro
                        </button>
                    }
                    {!showUpgradeButton &&
                        <button className="go-to-bai-button" onClick={() => window.location.href = "/"}>
                            Go to your presentations
                        </button>
                    }
                </div>}
        </div>);
    }
}

export default RedirectHandler;
